export class ValidityState {
  invalid = $state(false)
  reason = $state('')

  setInvalid(reason: string) {
    this.invalid = true
    this.reason = reason
  }
  setValid() {
    this.invalid = false
    this.reason = ''
  }
}

export const NAME_PATTERN = /^[a-zA-Z0-9\-_]+$/
const NAME_PATTERN_NOTICE =
  'Only lowercase and uppercase letters, numbers, dashes and underscores are allowed in this field'

export const nameValidator =
  (state: ValidityState) => (e: Event & { currentTarget: HTMLInputElement }) => {
    if (!e.currentTarget) {
      return false
    }
    const inputElement = e.currentTarget
    const validityState = inputElement.validity

    if (validityState.patternMismatch) {
      inputElement.setCustomValidity(NAME_PATTERN_NOTICE)
      state.setInvalid(NAME_PATTERN_NOTICE)
    } else {
      inputElement.setCustomValidity('')
      state.setValid()
    }
  }

export const NOT_URL_PATTERN = '^(?![a-zA-Z][a-zA-Z0-9+.\\-]*://).*'
