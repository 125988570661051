<script lang="ts">
  import { getModalStore, getToastStore } from '@skeletonlabs/skeleton'
  import { type SvelteComponent, untrack } from 'svelte'

  import { AssignableRoles, Role, userAssociationStore } from './user-association-store'
  import Input from '@components/Input.svelte'
  import Select from '@components/Select.svelte'
  import {
    organizationAsyncState,
    organizationsStore,
  } from '@routes/organizations/organizations-store'
  import { FetchError, handleFetch } from '@utils/fetch'

  const modalStore = getModalStore()
  const toastStore = getToastStore()

  let activeToast: string | null = null

  interface Props {
    /** Exposes parent props to this component. */
    parent: SvelteComponent
  }

  let { parent }: Props = $props()

  const { userAssociationId } = $modalStore[0].meta
  let userAssociation = $derived($userAssociationStore.get(userAssociationId))

  let editedUserAssociationRole: Role = $state(untrack(() => userAssociation?.role ?? Role.USER))
  userAssociationStore.subscribe(($userAssociationStore) => {
    const userAssociation = $userAssociationStore.get(userAssociationId)
    if (userAssociation) {
      editedUserAssociationRole = userAssociation.role
    }
  })

  const editUserAssociation = async () => {
    if (!userAssociation) {
      return
    }
    try {
      if (activeToast) {
        toastStore.close(activeToast)
      }
      await handleFetch(`/user-association/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          username: userAssociation.username,
          organizationId: userAssociation.organizationId,
          role: editedUserAssociationRole,
        },
      })
      userAssociationStore.update(($userAssociationStore) => {
        const existingUserAssociation = $userAssociationStore.get(userAssociationId)
        if (!existingUserAssociation) {
          return $userAssociationStore
        }
        return $userAssociationStore.set(userAssociationId, {
          ...existingUserAssociation,
          role: editedUserAssociationRole,
        })
      })
      modalStore.close()
    } catch (e) {
      console.error(e)
      if (e instanceof FetchError && e.reason) {
        activeToast = toastStore.trigger({
          message: `Failed to edit user association: ${e.reason}`,
          background: 'variant-filled-error',
          autohide: false,
        })
        return
      }
      activeToast = toastStore.trigger({
        message: 'Failed to edit user association',
        background: 'variant-filled-error',
      })
    }
  }

  const cancelEditUserAssociation = () => {
    modalStore.close()
  }
</script>

{#if $modalStore[0]}
  <div class="card w-modal space-y-4 p-4 shadow-xl">
    <header class="text-2xl font-bold">Change user association</header>
    <form onsubmit={editUserAssociation} class="space-y-4">
      <article class="space-y-4 border border-surface-500 p-4 rounded-container-token">
        <label for="user-email">User email:</label>
        <Input
          name="user-email"
          type="text"
          value={userAssociation?.username}
          minlength={1}
          disabled
        ></Input>
        <label for="associated-organization">Associated organization:</label>
        {#if $organizationAsyncState.loading}
          <p>Loading organizations...</p>
        {:else if $organizationAsyncState.error}
          <p>{$organizationAsyncState.error}</p>
        {:else}
          <Select name="associated-organization" value={userAssociation?.organizationId} disabled>
            <option selected value>Select an organization</option>
            {#each $organizationsStore as [_id, organization] (organization.id)}
              <option value={organization.id}>{organization.name}</option>
            {/each}
          </Select>
        {/if}
        <label for="role">Assigned role:</label>
        <Select
          name="role"
          bind:value={editedUserAssociationRole}
          disabled={editedUserAssociationRole == Role.OWNER}
        >
          <option selected value>Select a role</option>
          {#each AssignableRoles as role}
            <option value={role}>{role}</option>
          {/each}
        </Select>
      </article>
      <footer class="modal-footer {parent.regionFooter}">
        <button class="btn {parent.buttonNeutral}" onclick={cancelEditUserAssociation}
          >Cancel</button
        >
        <button type="submit" class="btn {parent.buttonPositive}">Save edit</button>
      </footer>
    </form>
  </div>
{/if}
