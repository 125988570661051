import type { ToastStore } from '@skeletonlabs/skeleton'
import { type FirebaseApp, initializeApp } from 'firebase/app'

import { handleFetch } from './fetch'

export interface Config {
  apiBaseUrl: string
  clientBaseUrl: string
  googleIdentityPlatform?: {
    apiKey: string
    authDomain: string
  }
  postHogId?: string
  clearmlAddress?: string
  clearmlProjectId?: string
  mlflowAddress?: string
  mlflowExperimentName?: string
  airGapped?: boolean
}

let config: Config | null = null
let firebaseApp: FirebaseApp | null = null

export const configureFirebase = (config: Config) => {
  if (firebaseApp) {
    return
  }

  const firebaseConfig = {
    apiKey: config.googleIdentityPlatform?.apiKey ?? 'AIzaSyAipKSfmBB0sY-pWDphYsGYd2ykbhRqGpA',
    authDomain: config.googleIdentityPlatform?.authDomain ?? 'hirundo-mvp-dev.firebaseapp.com',
  }

  firebaseApp = initializeApp(firebaseConfig)
}

export const getConfig = async (toastStore?: ToastStore): Promise<Config> => {
  if (config) {
    return config
  }
  const result = await (await handleFetch('config/config.json', {}, '', toastStore)).json()
  config = result
  return result
}
