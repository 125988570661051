import type { ToastStore } from '@skeletonlabs/skeleton'
import snakecaseKeys from 'snakecase-keys'

import { airGappedUserPasswordLocalStorage, forceLogout } from './auth'
import { getConfig } from './config'

let lastCsrfToken = ''
let isAirGapped: boolean
let airGappedUserPassword: string

export class FetchError extends Error {
  constructor(
    public statusCode: number,
    public reason: string,
  ) {
    super(reason)
    this.statusCode = statusCode
    this.name = 'FetchError'
  }
}

export const handleFetch = async <T extends string>(
  path: string,
  options: Omit<RequestInit, 'body'> & { body?: Partial<Record<T, unknown>> } = {},
  host?: string,
  toastStore?: ToastStore,
  retry = 0,
  sse = false,
): Promise<Response> => {
  if (retry > 3) {
    throw new FetchError(500, 'Failed to fetch data. Exceeded retry limit.')
  }
  if (host === undefined) {
    const config = await getConfig(toastStore)
    host = config.apiBaseUrl
    if (isAirGapped === undefined) {
      isAirGapped = config.airGapped ?? false
      airGappedUserPassword = localStorage.getItem(airGappedUserPasswordLocalStorage) ?? ''
    }
  }
  const useCsrf = ['POST', 'PUT', 'PATCH', 'DELETE'].includes(options.method ?? '')
  if (useCsrf && lastCsrfToken === '') {
    // ⬆️ Get a new CSRF token if we don't have one yet
    lastCsrfToken =
      (
        await fetch(`${host}/csrf`, {
          credentials: 'include',
        })
      ).headers.get('X-CSRF-Token') || ''
  }
  if (sse) {
    const eventSource = new EventSource(`${host}${path}`, {
      withCredentials: true,
    })
    const event: MessageEvent = await new Promise((resolve, reject) => {
      eventSource.onmessage = (event) => {
        resolve(event)
      }
      eventSource.onerror = (event) => {
        reject(event)
      }
    })
    eventSource.close()
    const response = new Response(event.data)
    return response
  }
  const response = await fetch(`${host}${path}`, {
    credentials: 'include',
    ...options,
    body: options.body ? JSON.stringify(snakecaseKeys(options.body)) : undefined,
    headers: {
      ...(options.body ? { 'Content-Type': 'application/json' } : {}),
      ...options.headers,
      ...(useCsrf ? { 'X-CSRF-Token': lastCsrfToken } : {}),
      ...(isAirGapped ? { Authorization: `Basic ${airGappedUserPassword}` } : {}),
    },
  })
  if (response.headers.has('X-CSRF-Token')) {
    // ⬆️ If the response has a new CSRF token, save it. This uses existing GET requests to store a CSRF
    lastCsrfToken = response.headers.get('X-CSRF-Token') || ''
  }
  if (useCsrf) {
    // ⬆️ If we used the CSRF token, clear it. It is no longer valid
    lastCsrfToken = ''
  }
  if (toastStore && response.headers.has('X-License-Status')) {
    let productString = ''
    let expiryString = ''
    if (response.headers.has('X-License-Product')) {
      productString = ` for ${response.headers.get('X-License-Product')} `
    }
    if (response.headers.has('X-License-Expires-In')) {
      expiryString = ` Your license expires in ${response.headers.get('X-License-Expires-In')}. `
    }
    if (response.headers.get('X-License-Status') === 'EXPIRED') {
      toastStore.trigger({
        message: `Your license ${productString}has expired. Please contact Hirundo to renew your license. Until you do so, no new optimization runs can be started.`,
        background: 'variant-filled-error',
      })
    } else if (response.headers.get('X-License-Status') === 'INVALID') {
      toastStore.trigger({
        message: `Your license ${productString}is invalid. Please contact Hirundo to obtain a valid license. Until you do so, no new optimization runs can be started.`,
        background: 'variant-filled-error',
      })
    } else if (response.headers.get('X-License-Status') === 'EXPIRES_SOON') {
      toastStore.trigger({
        message: `Your license ${productString}will expire in the next 60 days.${expiryString} Please contact Hirundo to renew your license.`,
        background: 'variant-filled-warning',
      })
    }
  }
  if (!response.ok) {
    if (response.status === 401) {
      let jsonResponse
      try {
        jsonResponse = await response.json()
      } catch (err) {
        console.error('Request failed!', host, path, err)
      }
      if (jsonResponse?.message === 'The CSRF signatures submitted do not match.') {
        console.error('CSRF token mismatch, reloading')
        lastCsrfToken = ''
        return handleFetch(path, options, host, toastStore, retry + 1)
      }
      console.error('Request returned 401, logging user out', host, path)
      await forceLogout()
      throw new FetchError(response.status, 'Unauthorized')
    }
    let message = ''
    try {
      const responseJson = await response.json()
      message = responseJson.reason
      if (!message) {
        if (
          responseJson.detail &&
          Array.isArray(responseJson.detail) &&
          responseJson.detail.length > 0
        ) {
          message = responseJson.detail
            .map((detail: { msg: string; loc: string[] }) =>
              detail.loc && detail.loc.length > 1
                ? `${detail.msg}: ${detail.loc
                    .slice(1)
                    // Slice is used to remove the first element of the loc array, which is always 'body'
                    .map((loc) =>
                      loc
                        .replaceAll('_', ' ')
                        .replace(/tagged-union\[(.*?)\]/, (_, args) => args.split(',').join(' or '))
                        .replace(/list\[(.*?)\]/, (_, args) => `list of ${args}`),
                    )
                    .join(' ➡️ ')}`
                : detail.msg,
            )
            .join('; ')
        } else if (responseJson.detail && typeof responseJson.detail === 'string') {
          message = responseJson.detail
        } else if (responseJson.message) {
          message = responseJson.message
        }
      }
    } catch (err) {
      console.error("Couldn't parse response JSON", host, path, err, 'Trying to parse as text')
      try {
        message = await response.text()
      } catch (err) {
        console.error('Request failed!', host, path, err)
        throw new FetchError(response.status, response.statusText)
      }
    }
    throw new FetchError(response.status, message)
  }
  return response
}
