<script lang="ts">
  import Icon from '@iconify/svelte'
  import { Accordion, AccordionItem, getModalStore, getToastStore } from '@skeletonlabs/skeleton'
  import type { SvelteComponent } from 'svelte'

  import { LabelingType } from '@src/routes/optimization-datasets/optimization-datasets-store.svelte'

  interface Props {
    /** Exposes parent props to this component. */
    parent: SvelteComponent
  }

  type AccordionEvent = CustomEvent<{
    event?: Event
    id: string
    panelId: string
    open: boolean
    autocollapse: boolean
  }>

  let { parent }: Props = $props()

  let useAdvanceRunOptions = $state(false)
  let upsample = $state(false)
  let minAbsBboxSize = $state(0)
  let minAbsBboxArea = $state(0)
  let minRelBboxSize = $state(0.0)
  let minRelBboxArea = $state(0.0)

  const toastStore = getToastStore()
  let activeToast: string | null = null

  const onFormCancel = () => {
    if ($modalStore[0].response) {
      $modalStore[0].response(false)
    }
    modalStore.close()
  }
  const onFormSubmit = () => {
    if (activeToast) {
      toastStore.close(activeToast)
    }

    if ($modalStore[0].response) {
      $modalStore[0].response(
        useAdvanceRunOptions
          ? {
              upsample,
              minAbsBboxSize,
              minAbsBboxArea,
              minRelBboxSize,
              minRelBboxArea,
            }
          : {},
      )
    }
    modalStore.close()
  }

  const modalStore = getModalStore()

  const accordionChange = (event: AccordionEvent) => {
    useAdvanceRunOptions = event.detail.open
  }
</script>

{#if $modalStore[0]}
  <div class="card w-modal space-y-4 p-4 shadow-xl">
    <header class="text-2xl font-bold">{$modalStore[0].title}</header>
    <article class="space-y-4 border border-surface-500 p-4 rounded-container-token">
      {$modalStore[0].body}
      <Accordion class="card variant-glass-surface mt-4">
        <AccordionItem on:toggle={accordionChange}>
          {#snippet lead()}
            <Icon icon="iconoir:settings" class="h-6 w-6" />
          {/snippet}
          {#snippet summary()}
            <h3>Advanced run options</h3>
          {/snippet}
          {#snippet content()}
            <form>
              {#if $modalStore[0].value.labelingType === LabelingType.SpeechToText}
                <p>No advanced options are available for Speech to Text datasets currently</p>
              {:else}
                <div class="grid grid-cols-[auto,1fr] gap-2">
                  <input
                    id="upsample"
                    name="upsample"
                    type="checkbox"
                    class="checkbox my-0"
                    bind:checked={upsample}
                  />
                  <label class="label" for="upsample">Upsample under represented classes</label>
                </div>
              {/if}
              {#if $modalStore[0].value.labelingType === LabelingType.ObjectDetection}
                <label class="label" for="minAbsBboxSize">Minimum absolute bbox size</label>
                <input
                  id="minAbsBboxSize"
                  name="minAbsBboxSize"
                  type="number"
                  class="input my-0 box-border flex w-fit min-w-96 px-2 py-1"
                  step={1}
                  bind:value={minAbsBboxSize}
                />
                <label class="label" for="minAbsBboxArea">Minimum absolute bbox area</label>
                <input
                  id="minAbsBboxArea"
                  name="minAbsBboxArea"
                  type="number"
                  class="input my-0 box-border flex w-fit min-w-96 px-2 py-1"
                  step={1}
                  bind:value={minAbsBboxArea}
                />
                <label class="label" for="minRelBboxSize">Minimum relative bbox size</label>
                <input
                  id="minRelBboxSize"
                  name="minRelBboxSize"
                  type="number"
                  class="input my-0 box-border flex w-fit min-w-96 px-2 py-1"
                  step={0.01}
                  bind:value={minRelBboxSize}
                />
                <label class="label" for="minRelBboxArea">Minimum relative bbox area</label>
                <input
                  id="minRelBboxArea"
                  name="minRelBboxArea"
                  type="number"
                  class="input my-0 box-border flex w-fit min-w-96 px-2 py-1"
                  step={0.01}
                  bind:value={minRelBboxArea}
                />
              {/if}
            </form>
          {/snippet}
        </AccordionItem>
      </Accordion>
    </article>
    <footer class="modal-footer {parent.regionFooter}">
      <button class="btn {parent.buttonNeutral}" onclick={onFormCancel}
        >{parent.buttonTextCancel}</button
      >
      <button class="btn {parent.buttonPositive}" onclick={onFormSubmit}
        >{parent.buttonTextConfirm}</button
      >
    </footer>
  </div>
{/if}
