<script>
  import { goto } from '$app/navigation'

  import GitHub from './GitHub.svelte'
  import Hirundo from './Hirundo.svelte'
  import Link from './Link.svelte'
  import { loggedInStore, User } from '@stores/logged-in-store'
  import { airGappedUserPasswordLocalStorage } from '@utils/auth'
  import { handleFetch } from '@utils/fetch'

  const logout = async () => {
    const response = await handleFetch('/user/logout')
    localStorage.setItem('authUser', '')
    if (localStorage.getItem(airGappedUserPasswordLocalStorage)) {
      localStorage.removeItem(airGappedUserPasswordLocalStorage)
    }
    loggedInStore.update(User.logout())
    if (response.ok) {
      await goto('/login', { invalidateAll: true })
    }
  }
</script>

<header>
  <div class="corner">
    <a href="https://hirundo.io" class="hirundo">
      <Hirundo />
    </a>
  </div>

  {#if $loggedInStore.loggedIn}
    <nav>
      <svg viewBox="0 0 2 3" aria-hidden="true" preserveAspectRatio="none">
        <path d="M0,0 L1,2 C1.5,3 1.5,3 2,3 L2,0 Z" />
      </svg>
      <ul>
        <Link path="/">Manage</Link>
        <Link path="/storage-configs">Storage</Link>
        <Link path="/optimization-datasets">Datasets</Link>
        <Link path="/dataset-optimization-runs">Optimization runs</Link>
        <Link onclick={logout}>Logout</Link>
      </ul>
      <svg viewBox="0 0 2 3" aria-hidden="true" preserveAspectRatio="none">
        <path d="M0,0 L0,3 C0.5,3 0.5,3 1,2 L2,0 Z" />
      </svg>
    </nav>
  {/if}

  <div class="corner invisible md:visible">
    <a href="https://github.com/Hirundo-io/" class="github">
      <GitHub />
    </a>
  </div>
</header>

<style>
  header {
    display: flex;
    justify-content: space-between;
  }

  .corner {
    width: 3em;
    height: 3em;
  }

  .corner a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .corner:has(:global(.hirundo)) {
    margin-left: 1rem;
    width: 8rem;
  }

  .corner:has(:global(.github)) {
    margin-right: 1rem;
    height: 100%;
  }

  nav {
    display: flex;
    justify-content: center;
    --background: rgb(248, 248, 250);
    --color-text-inverse: rgb(5, 13, 49);
  }

  svg {
    width: 2em;
    height: 3em;
    display: block;
  }

  path {
    fill: var(--background);
  }

  ul {
    position: relative;
    padding: 0;
    margin: 0;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    background: var(--background);
    background-size: contain;
  }
</style>
