import camelcaseKeys from 'camelcase-keys'
import { writable } from 'svelte/store'

import { handleFetch } from '@utils/fetch'

enum Role {
  OWNER = 'owner',
  ADMIN = 'admin',
  USER = 'user',
}
const AssignableRoles = [Role.ADMIN, Role.USER]

interface IUserAssociation {
  id: number
  username: string
  role: Role
  organizationId: number
  createdAt: Date
  updatedAt: Date
}

const userAssociationStore = writable(new Map<number, IUserAssociation>())

userAssociationStore.subscribe((userAssociations) => {
  console.log('User associations updated: ', userAssociations)
})

const parseUserAssociation = (
  rawUserAssociation: IUserAssociation & { createdAt: string; updatedAt: string },
): IUserAssociation => {
  const { id, username, role, organizationId, createdAt, updatedAt } = rawUserAssociation
  return {
    id,
    username,
    organizationId,
    role,
    createdAt: new Date(Date.parse(createdAt)),
    updatedAt: new Date(Date.parse(updatedAt)),
  }
}

const userAssociationsAsyncState = writable({
  loading: false,
  error: '',
})

const loadUserAssociations = async () => {
  userAssociationsAsyncState.set({
    loading: true,
    error: '',
  })
  type IRawUserAssociation = IUserAssociation & { createdAt: string; updatedAt: string }
  try {
    const userAssociations: IRawUserAssociation[] = camelcaseKeys(
      await (await handleFetch('/user-association/')).json(),
      { deep: true },
    )
    userAssociationStore.update(($userAssociationStore) => {
      return userAssociations.reduce((acc, userAssociation) => {
        acc.set(userAssociation.id, parseUserAssociation(userAssociation))
        return acc
      }, $userAssociationStore)
    })
    userAssociationsAsyncState.set({
      loading: false,
      error: '',
    })
  } catch (err) {
    console.error('Failed to load user associations', err)
    userAssociationsAsyncState.set({
      loading: false,
      error: 'Failed to load user associations',
    })
  }
}

export type { IUserAssociation }
export {
  AssignableRoles,
  loadUserAssociations,
  parseUserAssociation,
  Role,
  userAssociationsAsyncState,
  userAssociationStore,
}
