import { get } from 'svelte/store'

import {
  loadOptimizationDatasets,
  optimizationDatasetStore,
} from '../routes/optimization-datasets/optimization-datasets-store.svelte'
import { loadOrganizations, organizationsStore } from '../routes/organizations/organizations-store'
import {
  loadStorageConfig,
  storageConfigStore,
} from '../routes/storage-configs/storage-configs-store'
import { loggedInStore } from '../stores/logged-in-store'
import { loadUsers, usersStore } from '../stores/user-store'

export const checkAsyncState = (asyncState: { loading: boolean; error: string }) => {
  if (asyncState.loading) {
    return 'Loading...'
  } else if (asyncState.error) {
    return `Error: ${asyncState.error}`
  }
  return null
}

export const loadData = async () => {
  const promises = []

  if (get(optimizationDatasetStore).size === 0) promises.push(loadOptimizationDatasets())
  if (get(storageConfigStore).size === 0) promises.push(loadStorageConfig())
  if (get(organizationsStore).size === 0) promises.push(loadOrganizations())
  if (get(usersStore).length === 0 || get(loggedInStore).userId === 0) promises.push(loadUsers())

  await Promise.all(promises)
}

export const enum ClassesOption {
  AutoDetection = '1',
  ManualInput = '2',
}

export const throttle = <T extends (...args: unknown[]) => void>(func: T, limit: number): T => {
  let inThrottle: boolean

  return function (this: unknown, ...args: Parameters<T>) {
    if (!inThrottle) {
      func.apply(this, args)
      inThrottle = true
      setTimeout(() => (inThrottle = false), limit)
    }
  } as T
}
