import camelcaseKeys from 'camelcase-keys'
import { get, writable } from 'svelte/store'

import { loggedInStore } from '@stores/logged-in-store'
import { handleFetch } from '@utils/fetch'

interface IOrganization {
  id: number
  name: string
  description: string
  parentId: number | null
  createdAt: Date
  updatedAt: Date
  ownerId: number
  currentUserOwner: boolean
}

const organizationAsyncState = writable({
  loading: false,
  error: '',
})
const organizationsStore = writable(new Map<number, IOrganization>())

organizationsStore.subscribe((organizations) => {
  console.log('Organizations updated: ', organizations)
})

const parseOrganization = (
  rawOrganization: Omit<IOrganization, 'currentUserOwner'> & {
    createdAt: string
    updatedAt: string
  },
): IOrganization => {
  const { id, name, description, createdAt, updatedAt, parentId = null, ownerId } = rawOrganization
  return {
    id,
    name,
    description,
    parentId,
    createdAt: new Date(Date.parse(createdAt)),
    updatedAt: new Date(Date.parse(updatedAt)),
    ownerId,
    currentUserOwner: get(loggedInStore).userId == ownerId,
  }
}

const loadOrganizations = async () => {
  organizationAsyncState.set({
    loading: true,
    error: '',
  })
  type IOrganizationRaw = IOrganization & { createdAt: string; updatedAt: string }
  try {
    const organizations: IOrganizationRaw[] = camelcaseKeys(
      await (await handleFetch('/organization/')).json(),
      { deep: true },
    )
    const organizationMap = organizations.reduce((acc, organization) => {
      acc.set(organization.id, parseOrganization(organization))
      return acc
    }, new Map<number, IOrganization>())
    organizationsStore.set(organizationMap)
    organizationAsyncState.set({
      loading: false,
      error: '',
    })
  } catch (err) {
    console.error('Failed to load organizations: ', err)
    organizationAsyncState.set({
      loading: false,
      error: 'Failed to load organizations',
    })
  }
}

export type { IOrganization }
export { loadOrganizations, organizationAsyncState, organizationsStore, parseOrganization }
