import { goto } from '$app/navigation'
import { type ModalSettings, type ModalStore, type ToastStore } from '@skeletonlabs/skeleton'
import camelcaseKeys from 'camelcase-keys'
import { writable } from 'svelte/store'

import type { IStorageConfig } from '@src/routes/storage-configs/storage-configs-store'
import { FetchError, handleFetch } from '@utils/fetch'

enum LabelingType {
  SingleLabelClassification = 'SingleLabelClassification',
  ObjectDetection = 'ObjectDetection',
  SpeechToText = 'SpeechToText',
  ObjectSegmentation = 'ObjectSegmentation',
  SemanticSegmentation = 'SemanticSegmentation',
  PanopticSegmentation = 'PanopticSegmentation',
  // TODO: add more types after backend update
}

const labelingTypesValues = [
  LabelingType.SingleLabelClassification,
  LabelingType.ObjectDetection,
  LabelingType.ObjectSegmentation,
  LabelingType.SemanticSegmentation,
  LabelingType.PanopticSegmentation,
  LabelingType.SpeechToText,
] // Using this to determine the order that they are shown in visually
const displayLabelingTypes = {
  [LabelingType.SingleLabelClassification]: 'Single Label Classification',
  [LabelingType.ObjectDetection]: 'Object Detection',
  [LabelingType.SpeechToText]: 'Speech to Text',
  [LabelingType.ObjectSegmentation]: 'Object Segmentation',
  [LabelingType.SemanticSegmentation]: 'Semantic Segmentation',
  [LabelingType.PanopticSegmentation]: 'Panoptic Segmentation',
}

enum DatasetMetadataType {
  HirundoCSV = 'HirundoCSV',
  COCO = 'COCO',
  YOLO = 'YOLO',
  KeylabsObjDetImages = 'KeylabsObjDetImages',
  KeylabsObjDetVideo = 'KeylabsObjDetVideo',
  KeylabsObjSegImages = 'KeylabsObjSegImages',
  KeylabsObjSegVideo = 'KeylabsObjSegVideo',
}
const keylabsTypes = [
  DatasetMetadataType.KeylabsObjDetImages,
  DatasetMetadataType.KeylabsObjDetVideo,
  DatasetMetadataType.KeylabsObjSegImages,
  DatasetMetadataType.KeylabsObjSegVideo,
]

const datasetMetadataTypeValuesByLabelingType = {
  [LabelingType.SingleLabelClassification]: [DatasetMetadataType.HirundoCSV],
  [LabelingType.ObjectDetection]: [
    DatasetMetadataType.HirundoCSV,
    DatasetMetadataType.COCO,
    DatasetMetadataType.YOLO,
    DatasetMetadataType.KeylabsObjDetImages,
    DatasetMetadataType.KeylabsObjDetVideo,
  ],
  [LabelingType.SpeechToText]: [DatasetMetadataType.HirundoCSV],
  [LabelingType.ObjectSegmentation]: [
    DatasetMetadataType.HirundoCSV,
    DatasetMetadataType.KeylabsObjSegImages,
    DatasetMetadataType.KeylabsObjSegVideo,
  ],
  [LabelingType.SemanticSegmentation]: [DatasetMetadataType.HirundoCSV],
  [LabelingType.PanopticSegmentation]: [DatasetMetadataType.HirundoCSV],
}
const displayMetadataTypes = {
  [DatasetMetadataType.HirundoCSV]: 'Hirundo CSV',
  [DatasetMetadataType.COCO]: 'COCO',
  [DatasetMetadataType.YOLO]: 'YOLO',
  [DatasetMetadataType.KeylabsObjDetImages]: 'Keylabs Object Detection Images',
  [DatasetMetadataType.KeylabsObjDetVideo]: 'Keylabs Object Detection Video',
  [DatasetMetadataType.KeylabsObjSegImages]: 'Keylabs Object Segmentation Images',
  [DatasetMetadataType.KeylabsObjSegVideo]: 'Keylabs Object Segmentation Video',
}

abstract class ILabelingInfo {
  public type?: DatasetMetadataType
}

class HirundoCSV extends ILabelingInfo {
  public type: DatasetMetadataType.HirundoCSV = DatasetMetadataType.HirundoCSV
  public csvUrl: string = $state('')

  toJSON() {
    return {
      type: this.type,
      csvUrl: this.csvUrl,
    }
  }
}

class COCO extends ILabelingInfo {
  public type: DatasetMetadataType.COCO = DatasetMetadataType.COCO
  public jsonUrl: string = $state('')

  toJSON() {
    return {
      type: this.type,
      jsonUrl: this.jsonUrl,
    }
  }
}

class YOLO extends ILabelingInfo {
  public type: DatasetMetadataType.YOLO = DatasetMetadataType.YOLO
  public dataYamlUrl: string | null = $state(null)
  public labelsDirUrl: string = $state('')

  toJSON() {
    return {
      type: this.type,
      dataYamlUrl: this.dataYamlUrl,
      labelsDirUrl: this.labelsDirUrl,
    }
  }
}

class KeylabsAuth {
  username: string = $state('')
  password: string = $state('')
  instance: string = $state('')

  toJSON() {
    return {
      username: this.username,
      password: this.password,
      instance: this.instance,
    }
  }
}

class Keylabs extends ILabelingInfo {
  projectId: string = $state('')
  /* Keylabs project ID. */

  labelsDirUrl: string = $state('')
  /* URL to the directory containing the Keylabs labels. */

  withAttributes = $state(true)
  /* Whether to include attributes in the class name. */

  projectName: string | null = $state(null)
  /* Keylabs project name (optional; added to output CSV if provided). */

  keylabsAuth: KeylabsAuth | null = $state(null)
  /* Keylabs authentication credentials (optional; if provided, used to provide links to each sample). */

  toJSON() {
    return {
      type: this.type,
      projectId: this.projectId,
      labelsDirUrl: this.labelsDirUrl,
      withAttributes: this.withAttributes,
      projectName: this.projectName,
      keylabsAuth: this.keylabsAuth?.toJSON() ?? null,
    }
  }
}

class KeylabsObjDetImages extends Keylabs {
  public type: DatasetMetadataType.KeylabsObjDetImages = DatasetMetadataType.KeylabsObjDetImages
}

class KeylabsObjDetVideo extends Keylabs {
  public type: DatasetMetadataType.KeylabsObjDetVideo = DatasetMetadataType.KeylabsObjDetVideo
}

class KeylabsObjSegImages extends Keylabs {
  public type: DatasetMetadataType.KeylabsObjSegImages = DatasetMetadataType.KeylabsObjSegImages
}

class KeylabsObjSegVideo extends Keylabs {
  public type: DatasetMetadataType.KeylabsObjSegVideo = DatasetMetadataType.KeylabsObjSegVideo
}

const isHirundoCSV = (labelingInfo?: ILabelingInfo): labelingInfo is HirundoCSV => {
  return labelingInfo?.type == DatasetMetadataType.HirundoCSV
}

const isCOCO = (labelingInfo?: ILabelingInfo): labelingInfo is COCO => {
  return labelingInfo?.type == DatasetMetadataType.COCO
}

const isYOLO = (labelingInfo?: ILabelingInfo): labelingInfo is YOLO => {
  return labelingInfo?.type == DatasetMetadataType.YOLO
}

const isKeylabs = (labelingInfo?: ILabelingInfo): labelingInfo is Keylabs => {
  return !!labelingInfo?.type && keylabsTypes.includes(labelingInfo?.type)
}

// TODO: refactor fields after backend update
interface IOptimizationDataset {
  id: number
  name: string
  labelingType: LabelingType
  language?: string
  organizationId?: number
  storageConfig: IStorageConfig
  classes: string[] | null
  dataRootUrl: string
  labelingInfo: ILabelingInfo
  createdAt: Date
  updatedAt: Date
  creatorId: number
  creatorName?: string
  datasetMetadataType: DatasetMetadataType
}

type IRawOptimizationDataset = Omit<IOptimizationDataset, 'createdAt' | 'updatedAt'> & {
  createdAt: string
  updatedAt: string
}

const optimizationDatasetStore = writable(new Map<number, IOptimizationDataset>())

optimizationDatasetStore.subscribe((datasets) => {
  console.log('dataset updated: ', datasets)
})

const parseOptimizationDatasets = (rawDatasets: IRawOptimizationDataset): IOptimizationDataset => {
  const {
    id,
    name,
    labelingType,
    language,
    organizationId,
    classes,
    dataRootUrl,
    labelingInfo,
    createdAt,
    updatedAt,
    creatorId,
    creatorName,
    storageConfig,
    datasetMetadataType,
  } = rawDatasets

  return {
    id,
    name,
    labelingType,
    language,
    organizationId,
    classes,
    dataRootUrl,
    labelingInfo,
    createdAt: new Date(Date.parse(createdAt)),
    updatedAt: new Date(Date.parse(updatedAt)),
    creatorId,
    creatorName,
    storageConfig,
    datasetMetadataType,
  }
}

const optimizationDatasetAsyncState = writable({
  loading: false,
  error: '',
})

const loadOptimizationDatasets = async () => {
  optimizationDatasetAsyncState.set({
    loading: true,
    error: '',
  })
  try {
    const optimizations: IRawOptimizationDataset[] = camelcaseKeys(
      await (await handleFetch('/dataset-optimization/dataset/')).json(),
      { deep: true },
    )
    const optimizationMap = optimizations.reduce((acc, optimization) => {
      acc.set(optimization.id, parseOptimizationDatasets(optimization))
      return acc
    }, new Map<number, IOptimizationDataset>())
    optimizationDatasetStore.set(optimizationMap)
    optimizationDatasetAsyncState.set({
      loading: false,
      error: '',
    })
  } catch (err) {
    console.error('Failed to load optimizations: ', err)
    optimizationDatasetAsyncState.set({
      loading: false,
      error: 'Failed to load optimizations',
    })
  }
}

const runOptimization = async (
  dataset: { labelingType: LabelingType },
  datasetId: number,
  toastStore: ToastStore,
  modalStore: ModalStore,
  optimizationName: string | undefined,
) => {
  const modal: ModalSettings = {
    type: 'component',
    component: 'runDatasetOptimizationModal',
    // Data
    title: 'Run dataset optimization',
    body: 'You can customize run parameters in the advanced section below if you need or just click "Run now" to start the optimization run.',
    buttonTextConfirm: 'Run now',
    value: dataset,

    response: async (
      r:
        | {
            upsample: boolean
            minAbsBboxSize: number
            minAbsBboxArea: number
            minRelBboxSize: number
            minRelBboxArea: number
          }
        | boolean,
    ) => {
      if (!r) {
        return
      }
      try {
        await Promise.all([
          handleFetch(`/dataset-optimization/run/${datasetId}`, {
            method: 'POST',
            body: r ? { runArgs: r } : undefined,
          }),
          new Promise((resolve) => setTimeout(resolve, 1_000)),
        ])
        toastStore.trigger({
          message: `Started optimization run for dataset named <strong>${optimizationName}<strong>`,
          background: 'variant-filled-success',
        })
        goto(`/dataset-optimization-runs`)
      } catch (err) {
        console.error(err)
        if (err instanceof FetchError && err.message) {
          toastStore.trigger({
            message: `Failed to launch optimization run for dataset with error: ${err.message}`,
            background: 'variant-filled-error',
            autohide: false,
          })
          throw err
        }
        toastStore.trigger({
          message: 'Failed to launch optimization run for dataset',
          background: 'variant-filled-error',
        })
        throw err
      }
    },
  }
  modalStore.trigger(modal)
}

export type { IOptimizationDataset }
export {
  COCO,
  DatasetMetadataType,
  datasetMetadataTypeValuesByLabelingType,
  displayLabelingTypes,
  displayMetadataTypes,
  HirundoCSV,
  ILabelingInfo,
  isCOCO,
  isHirundoCSV,
  isKeylabs,
  isYOLO,
  KeylabsAuth,
  KeylabsObjDetImages,
  KeylabsObjDetVideo,
  KeylabsObjSegImages,
  KeylabsObjSegVideo,
  LabelingType,
  labelingTypesValues,
  loadOptimizationDatasets,
  optimizationDatasetAsyncState,
  optimizationDatasetStore,
  parseOptimizationDatasets,
  runOptimization,
  YOLO,
}
